import { CLEAR_ALL, GET_ALL_CLUSTERS, CLUSTER_CREATED, REMOVE_CLUSTER, GET_CLUSTER } from '../types';

const initialState = {
  allClusters: [],
  allClustersLoading: true,
  clusterCreatedStatus: false,
  cluster: {},
  clusterLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case GET_CLUSTER:
      return {
        ...state,
        clusterLoading: false,
        cluster: action.payload,
      };
    case CLUSTER_CREATED:
      return {
        ...state,
        clusterCreatedStatus: action.payload,
      };
    case GET_ALL_CLUSTERS:
      return {
        ...state,
        allClusters: action.payload,
        allClustersLoading: false,
      };
    case REMOVE_CLUSTER:
      return {
        ...state,
        allClusters: state.allClusters.filter((cluster) => cluster.cluster_id !== action.payload),
      };
    case CLEAR_ALL:
      return {
        ...state,
        allClusters: [],
        allClustersLoading: true,
      };

    default:
      return state;
  }
}
