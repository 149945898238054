import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import TrainingInProgress from '../../../components/training-progress';
import useResponsive from '../../../hooks/useResponsive';
import { trainDocuments } from '../../../store/actions/train/trainDocuments';
import FileSelectionLogic from './helpers/FileSelectLogic';
import SelectedFilesGrid from './helpers/SelectedFiles';
import { checkUserAccess } from '../../../store/actions/user/checkAccess';

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [tempSelected, setTempSelected] = useState([]);
  const [chunkedOutputs, setChunkedOutputs] = useState([]);
  const [chunkingInProgress, setChunkingInProgress] = useState(false);
  const isMobileOrTablet = useResponsive('down', 'md');

  const dispatch = useDispatch();
  const trainingInProgress = useSelector((state) => state.training.trainingInProgress);

  const handleDeleteFile = (fileToDelete) => {
    setChunkedOutputs((prevSelectedFiles) => prevSelectedFiles.filter((file) => file.file.name !== fileToDelete.name));
    setSelectedFile((prevSelectedFiles) => prevSelectedFiles.filter((file) => file.name !== fileToDelete.name));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileSelect = (files) => {
    setSelectedFile(files);
  };

  const handleCancelTrain = () => {
    setSelectedFile([]);
    setTempSelected([]);
    setChunkedOutputs([]);
    setChunkingInProgress(false);
  };

  const handleTrainDocument = async () => {
    if (!chunkingInProgress && chunkedOutputs && !trainingInProgress) {
      const access = await checkUserAccess();
      if (access && access.success === false) {
        toast.error(access.message);
      } else {
        dispatch(trainDocuments(chunkedOutputs));
        setSelectedFile([]);
        setTempSelected([]);
        setChunkedOutputs([]);
        setChunkingInProgress(false);
      }
    } else {
      toast.error('Please make sure you have uploaded file(s) before training.');
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        className="flex justify-center align-center p-5 lg:py-14"
        style={{ paddingTop: isMobileOrTablet ? '0px' : '90px' }}
      >
        <Grid item md={12} xs={12} style={{ paddingTop: isMobileOrTablet ? '0px' : '0px' }}>
          <Grid
            container
            spacing={3}
            className="flex justify-center align-center"
            style={{ paddingTop: isMobileOrTablet ? '0px' : '0px' }}
          >
            <Grid
              item
              md={11}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: isMobileOrTablet ? '0px' : '0px',
              }}
            >
              <FileSelectionLogic
                trainingInProgress={trainingInProgress}
                selectedFile={selectedFile}
                chunkedOutputs={chunkedOutputs}
                setSelectedFile={setSelectedFile}
                onSelect={handleFileSelect}
                setChunkedOutputs={setChunkedOutputs}
                handleDragOver={handleDragOver}
                setChunkingInProgress={setChunkingInProgress}
                tempSelected={tempSelected}
                setTempSelected={setTempSelected}
              />
            </Grid>
            <Grid item md={11} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <SelectedFilesGrid
                chunkingInProgress={chunkingInProgress}
                selectedFile={selectedFile}
                chunkedOutputs={chunkedOutputs}
                handleDeleteFile={handleDeleteFile}
                handleTrainDocument={handleTrainDocument}
                handleCancelTrain={handleCancelTrain}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TrainingInProgress />
    </>
  );
};

export default Upload;
