import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useResponsive from '../hooks/useResponsive';

export default function Questions() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <Card>
      <CardContent>
        <Typography variant="h3">Frequently Asked Questions</Typography>
        <Box sx={{ mt: 4 }}>
          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">How to use this AI tool?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  This tool serves as an invaluable resource for anyone seeking information about medical affairs. It
                  offers rapid and accurate answers to your queries, drawing from a vast database of meticulously
                  curated information. Whether you're a researcher, medical science liaison, medical affairs manager or
                  other medical affairs associated professionals, this tool can provide you with comprehensive insights,
                  saving you time and effort in your quest for knowledge.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />
          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">Who is the ACMA?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  ACMA, the Accreditation Council for Medical Affairs, sets quality and competency standards for the
                  life sciences and pharma industry. Established in 2015, it's an independent accrediting body in the
                  life sciences sector. ACMA credentials individuals and accredits practices to ensure rigorous
                  quality/competency standards globally, advancing patient care.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">How can I use this tool?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Using this tool is straightforward and user-friendly. Simply enter your question related to medical
                  affairs into the search bar. The AI-powered system will process your question and provide you with a
                  well-informed response based on its extensive training data. Whether you have a specific inquiry or
                  are looking for general information, the tool is designed to cater to a wide range of queries.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">What is the difference between external and internal data?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  When you choose internal data, the AI exclusively sources answers from the documents you've trained
                  MedAffairs AI with. Conversely, selecting external data allows the AI to utilize ACMA's comprehensive
                  medical affairs data compendium to respond to your queries.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">What type of documents can be trained to MedAffairs AI?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  MedAffairsAI can be trained with a range of file formats, including PDFs, text files, Excel documents,
                  images, and we are actively working on incorporating compatibility for audio files, doc files, and
                  video files into our roadmap.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">What types of questions can this tool answer?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  This tool is designed to answer a broad spectrum of questions related to medical affairs. You can ask
                  about definitions, inquire about medical information, regulatory affairs and compliance, KOL
                  engagement, medical strategy, medical planning and much more.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider sx={{ pt: 1, pb: 1 }} />

          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h5">Is this tool secure?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Absolutely, security is a top priority for any of our tools. Be assured, your search questions aren’t
                  stored in our database.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
