import { Auth } from 'aws-amplify';
import axios from 'axios';
import { EXTERNAL_DB_UPLOAD } from '../../API';

export const uploadDocumentsToExternalDb = async (fileTrainingOutput) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userid = user?.username;

  const postData = {
    userid,
    email,
    files: fileTrainingOutput,
  };

  console.log('---- ready to upload to external');

  try {
    const response = await axios.post(EXTERNAL_DB_UPLOAD, postData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
    });
    console.log('---- succesfully uploaded to external');
    return response.data;
  } catch (err) {
    console.error('error upload to external db', err);
    return null;
  }
};
