import React from 'react';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { withAuth } from '../../../components/HOC/withAuth';
import useResponsive from '../../../hooks/useResponsive';

function Main() {
  const isMobileOrTablet = useResponsive('down', 'md');

  return (
    <>
      <Container>
        <div className="h-full flex flex-col pb-6" style={{ marginTop: isMobileOrTablet ? '' : '100px' }}>
          <div className="flex flex-col justify-center">
            <div className="sm:mt-10 md:-mt-20 max-w-4xl w-full text-center mx-auto px-4 sm:px-6 lg:px-8 flex justify-center items-center flex-col">
              <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl">Welcome to MedAffairs AI</h1>
              <p className="mt-3 text-gray-600">Real-time Medical Affairs Insights When You Need It</p>
            </div>
            <div>
              <div
                className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
                style={{ marginTop: isMobileOrTablet ? '0px' : '30px' }}
              >
                <div className="grid lg:grid-cols-2 lg:gap-y-16 gap-10">
                  <Link className="group rounded-xl overflow-hidden" to="/external">
                    <div>
                      <div
                        className="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-full"
                        style={{ height: '14rem' }}
                      >
                        <img
                          className="group-hover:scale-105 transition-transform duration-500 ease-in-out size-full absolute top-0 start-0 object-cover rounded-xl"
                          src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/ai/external-v3.svg"
                          alt="Description"
                        />
                      </div>

                      <div className="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0" style={{ marginTop: '20px' }}>
                        <h3 className="text-xl font-bold text-gray-800 group-hover:text-gray-600">
                          Search External Data
                        </h3>
                        <p className="mt-3 text-gray-600">
                          Access a wealth of knowledge in medical affairs by simply asking your questions.
                        </p>
                        <a
                          href="/external"
                          className="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium"
                        >
                          Get started
                          <svg
                            className="flex-shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </Link>

                  <Link className="group rounded-xl overflow-hidden" to="/internal">
                    <div>
                      <div
                        className="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-full h-44"
                        style={{ height: '14rem' }}
                      >
                        <img
                          className="group-hover:scale-105 transition-transform duration-500 ease-in-out size-full absolute top-0 start-0 object-cover rounded-xl"
                          src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/ai/internal-v3.svg"
                          alt="Description"
                        />
                      </div>

                      <div className="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0" style={{ marginTop: '20px' }}>
                        <p className="text-xl font-bold text-gray-800 group-hover:text-gray-600">
                          Search Internal Data
                        </p>
                        <p className="mt-3 text-gray-600">
                          Effortlessly retrieve answers by querying your internally uploaded documents.
                        </p>
                        <p className="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                          Get started
                          <svg
                            className="flex-shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default withAuth(Main);
