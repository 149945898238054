import axios from 'axios';
import toast from 'react-hot-toast';
import { Auth } from 'aws-amplify';

import { NEW_CREATE_CLUSTER } from '../../API';
import { CLUSTER_CREATED } from '../../types';

export const createCluster = (clusterName, selectedDocuments) => async (dispatch) => {
  dispatch({
    type: CLUSTER_CREATED,
    payload: false,
  });
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userId = user?.username;

  const params = {
    email,
    userId,
    clusterName,
    selectedDocuments,
  };

  try {
    const response = await axios.post(NEW_CREATE_CLUSTER, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    // console.log('res create', response);
    if (response && response.data && response.data.success === true) {
      toast.success('Cluster succesfully created');
      dispatch({
        type: CLUSTER_CREATED,
        payload: true,
      });
    } else {
      toast.error(response.data.message);
      dispatch({
        type: CLUSTER_CREATED,
        payload: false,
      });
    }
  } catch (error) {
    console.error('Error creating cluster:', error);
    toast.error('Error creating cluster. Please try again!!');
    dispatch({
      type: CLUSTER_CREATED,
      payload: false,
    });
  }
};
