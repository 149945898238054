// component
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import PublicIcon from '@mui/icons-material/Public';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

// ----------------------------------------------------------------------

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/app',
    icon: <GridViewRoundedIcon />,
  },
  {
    title: 'External Q/A',
    path: '/external',
    icon: <PublicIcon />,
  },
  {
    title: 'Internal Q/A',
    path: '/internal',
    icon: <PlagiarismIcon />,
  },

  {
    title: 'Documents',
    path: '/documents',
    icon: <DocumentScannerIcon style={{ color: '#637381' }} />,
    collapse: true,
    child: [
      {
        title: 'Train Documents',
        path: '/upload-documents',
        icon: <FiberManualRecordRoundedIcon style={{ fontSize: '10px' }} />,
      },
      {
        title: 'Trained Documents',
        path: '/uploaded-documents',
        icon: <FiberManualRecordRoundedIcon style={{ fontSize: '10px' }} />,
      },
      {
        title: 'Clusters',
        path: '/clusters',
        icon: <FiberManualRecordRoundedIcon style={{ fontSize: '10px' }} />,
      },
    ],
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
    icon: <SupportAgentIcon />,
  },
];

export default navConfig;
