import { Auth } from 'aws-amplify';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { DELETE_EXTERNAL_DB_API } from '../../API';
import { DOCUMENT_DELETION_IN_PROGRESS } from '../../types';

export const deleteDocument = (document) => async (dispatch) => {
  console.log('document to delete', document);
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userId = user?.username;

  const params = {
    email,
    userId,
    document: JSON.stringify(document),
  };

  console.log('---- ready to delete', params);

  dispatch({
    type: DOCUMENT_DELETION_IN_PROGRESS,
    payload: true,
  });
  try {
    const res = await axios.post(
      'https://5net7sfui7.execute-api.us-east-1.amazonaws.com/default/delete-document',
      params,
      {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer '.concat(token),
        },
      }
    );
    if (res && res.data && res.data === true) {
      console.log('delete res', res.data);
      toast.success('Succesfully Removed');
      dispatch({
        type: DOCUMENT_DELETION_IN_PROGRESS,
        payload: false,
      });
    } else {
      toast.error('We were unable to remove your file, please try again');
    }
  } catch (err) {
    dispatch({
      type: DOCUMENT_DELETION_IN_PROGRESS,
      payload: false,
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
