import { Link } from 'react-router-dom';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Grid, Typography } from '@mui/material';

export default function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-row">
            <div className="footer-col">
              <h4>ACMA AI</h4>
              <ul>
                <li>
                  <Link to="/">Medical Affairs AI</Link>
                </li>
                <li>
                  <a href="https://reimbursementai.org/">Reimbursement & Prior Auth AI</a>
                </li>
                <li>
                  <Link to="https://enterprise.medicalaffairsspecialist.org/aimedinfo">AIMedInfo</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>ACMA Certifications</h4>
              <ul>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/certifications/bcmas">
                    Board Certified Medical Affairs Speclialist
                  </Link>
                </li>
                <li>
                  <Link to="https://www.priorauthtraining.org/">Prior Authorization Certified Specialist</Link>
                </li>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/certifications/bcbbs">
                    Board Certified Biologics and Biosimilars Program
                  </Link>
                </li>
                <li>
                  <Link to="https://www.pharmasalestraining.org/">Pharmaceutical Representative Sales Program</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>get help</h4>
              <ul>
                <li>
                  <Link to="/demo">Contact Us</Link>
                </li>
                <li>
                  <Link to="/questions">FAQ</Link>
                </li>
                <li>
                  <a href="https://medicalaffairsspecialist.org/about">About ACMA</a>
                </li>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/policy/terms-and-conditions">Terms & Conditions</Link>
                </li>
              </ul>
            </div>

            <div className="footer-col">
              <h4>follow us</h4>
              <div>
                <h5 style={{ color: 'white' }}>Accreditation Council For Medical Affairs (ACMA)</h5>
                <div>
                  <a href="https://www.facebook.com/medicalaffairsspecialist/">
                    <FacebookRoundedIcon className="footer-social-icons" />
                  </a>
                  <a href="https://twitter.com/The_ACMA?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                    <TwitterIcon className="footer-social-icons ml-2" />
                  </a>
                  <a href="https://www.instagram.com/acmapharma/">
                    <InstagramIcon className="footer-social-icons ml-2" />
                  </a>
                  <a href="https://www.linkedin.com/company/accreditation-council-for-medical-affairs-acma-/mycompany/verification/">
                    <LinkedInIcon className="footer-social-icons ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={12} className="flex justify-center align-center">
              <Typography
                varaint="h7"
                style={{ textAlign: 'center', color: '#bbbbbb', fontSize: '16px' }}
                className="flex mt-4 text-center"
              >
                Copyright © 2024 Accreditation Council for Medical Affairs. All Rights Reserved.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </footer>
    </div>
  );
}
