import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { GET_COMPANY_DOCUMENTS } from '../../types';
import { DOWNLOAD_FILE, NEW_DOWNLOAD_URL } from '../../API';
import { processPdfFile } from '../../../helpers/chunking/processPdfFile';

export const downloadFile = (fileName) => async () => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userId = user?.username.substring(0, 8).toLowerCase();

  try {
    const res = await axios.get(NEW_DOWNLOAD_URL, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        userId,
        email,
        fileName,
      },
    });
    if (res) {
      // console.log('documents', res);
      if (res.data && res.data.length >= 1) {
        const link = document.createElement('a');
        link.href = res.data;
        link.target = '_blank';
        link.download = fileName;
        link.rel = 'noopener noreferrer';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success('Document Downloaded Successfully');
      } else {
        toast.error('Error getting your file. Please try again!');
      }
    }
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

export const downloadFileAndGetText = (fileName) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;

  try {
    const res = await axios.get(DOWNLOAD_FILE, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
        fileName,
      },
    });
    if (res) {
      console.log('documents', res);
      if (res.data && res.data.length >= 1) {
        const fileData = new Blob([res.data]);
        // console.log(fileData);

        // Now, you can call the processPdfFile function and pass the fileData as a parameter
        const processedData = await processPdfFile(fileData);

        // Access the processed data as needed, for example, chunks of text
        const chunks = processedData.chunks;
        // console.log(chunks);
      } else {
        toast.error('Error getting your file. Please try again!');
      }
    }
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
