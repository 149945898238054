import { Auth } from 'aws-amplify';
import axios from 'axios';
import { USER } from '../../API';
import { GET_EXTERNAL_DB_USER } from '../../types';

export const getExterenalDbUser = () => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  try {
    // Make an API call to your external database to retrieve user info
    const response = await axios.get(USER, {
      params: {
        email,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const userData = response.data;
      dispatch({
        type: GET_EXTERNAL_DB_USER,
        payload: userData?.user,
      });
    }
  } catch (error) {
    console.log('error getting user info', error);
  }
};
