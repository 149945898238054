import axios from 'axios';
import toast from 'react-hot-toast';
import { POST_REGISTRATION } from '../../API';

export async function updateUserOnExternalDb(email, userId, name) {
  const postData = {
    email,
    userId,
    name,
  };

  try {
    const response = await axios.post(POST_REGISTRATION, postData, {
      headers: {
        'content-type': 'application/json',
      },
    });

    if (response.data && response.data.success === true) {
      return true;
    }
    return false;
  } catch (error) {
    // console.log('err updating user', error);
    return false;
  }
}
