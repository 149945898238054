import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const isDesktop = useResponsive('up', 'md');
  const logo = 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/ai/medaffairsai.png';

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      <img src={logo} alt="acma-logo" width={isDesktop ? '100%' : '100%'} />
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
