// @mui
import { Container } from '@mui/material';
import { styled } from '@mui/system';
// External
import { useNavigate } from 'react-router-dom';
// import SignInForm from './SignInForm';
// Hooks
import { withNoAuth } from '../../../components/HOC/withNoAuth';
import ForgotPasswordForm from './ForgotPasswordForm';

// Page - To allow users to sign in
// ----------------------------------------------------------------------

// Overwiting Styles
const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

function ForgotPassword() {
  const navigate = useNavigate();

  return (
    <StyledRoot>
      <StyledSection
        style={{
          boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
        }}
      >
        {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          Forgot Password?
        </Typography> */}
        <div className="flex justify-center items-center">
          <img
            src={
              'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/forgot-password.svg'
            }
            alt="forgot-password"
            width="500"
            height="500"
          />
        </div>
      </StyledSection>
      <Container maxWidth="sm">
        <ForgotPasswordForm />
      </Container>
    </StyledRoot>
  );
}

export default withNoAuth(ForgotPassword);
