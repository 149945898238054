import * as React from 'react';
import { Button, Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Auth } from 'aws-amplify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Logo from '../../components/logo/Logo';
import { useGetUser } from '../../hooks/useGetUser';
import MedAffairsMenu from './MedAffairsMenu';
import useResponsive from '../../hooks/useResponsive';
import MedAffairsMenuMobile from './MedAffairsMenuMobile';

function CustomAppBar() {
  const navigate = useNavigate();
  const { user, loading } = useGetUser();
  const isDesktop = useResponsive('up', 'md');

  const logout = async () => {
    try {
      await Auth.signOut();
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Container
        sx={{ maxWidth: isDesktop ? '1305px' : '100%', padding: '0px', pt: isDesktop ? 8 : 3 }}
        maxWidth={false}
      >
        <Toolbar disableGutters sx={{ display: 'block' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isDesktop ? <></> : <MedAffairsMenuMobile />}

            <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
              {/* Mango Change */}
              <img
                src={
                  'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/medaffairsai+white+(1).png'
                }
                alt="acma-logo"
                width={isDesktop ? '35%' : '40%'}
              />
            </Link>

            {loading ? (
              <></>
            ) : user ? (
              <>
                <Button
                  variant="container"
                  size="large"
                  sx={{
                    backgroundColor: 'white',
                    fontSize: '18px',
                    color: 'black',
                    '&:hover': { backgroundColor: '#dfdfdf' },
                  }}
                  onClick={logout}
                >
                  Logout
                </Button>
              </>
            ) : (
              <Box sx={{ display: 'flex' }}>
                {isDesktop ? <MedAffairsMenu /> : <></>}

                <Button
                  variant="container"
                  size="large"
                  style={{
                    marginLeft: '20px',
                    fontSize: '18px',
                    marginRight: '15px',
                    backgroundColor: '#3771bb',
                    padding: '20px 30px',
                    color: 'white',
                    borderRadius: '6px',
                    borderBottom: '4px solid #1a3e6c',
                    borderTop: '0',
                    transition: 'all 0.1s ease-in-out',
                  }}
                  onClick={(e) => navigate('/login')}
                >
                  Login
                </Button>
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default CustomAppBar;
