import axios from 'axios';
import toast from 'react-hot-toast';
import { Auth } from 'aws-amplify';

import { NEW_UPDATE_CLUSTER } from '../../API';
import { CLUSTER_UPDATED } from '../../types';

export const updateCluster = (clusterId, clusterName, selectedDocuments) => async (dispatch) => {
  dispatch({
    type: CLUSTER_UPDATED,
    payload: false,
  });
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userId = user?.username;

  const params = {
    email,
    userId,
    clusterId,
    clusterName,
    selectedDocuments,
  };

  try {
    const response = await axios.put(NEW_UPDATE_CLUSTER, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    // console.log('res update', response);
    if (response && response.data && response.data.success === true) {
      toast.success('Cluster succesfully updated');
      dispatch({
        type: CLUSTER_UPDATED,
        payload: true,
      });
    } else {
      toast.error(response.data.message);
      dispatch({
        type: CLUSTER_UPDATED,
        payload: false,
      });
    }
  } catch (error) {
    console.error('Error updating cluster:', error);
    toast.error('Error updating cluster. Please try again!!');
    dispatch({
      type: CLUSTER_UPDATED,
      payload: false,
    });
  }
};
