import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Card, CardContent, Typography, CardActionArea, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import Label from '../../../components/label/Label';
import { useGetAllClusters } from '../../../hooks/useGetAllClusters';
import { formatString } from '../../../helpers/formatString';

export default function SelectCluster({ selectedCluster, setSelectedCluster }) {
  const { allClusters, allClustersLoading } = useGetAllClusters();
  const [searchQuery, setSearchQuery] = useState('');

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectCluster = (item) => {
    setSelectedCluster(item);
  };

  const cancelClusterSelect = () => {
    setSelectedCluster({});
    setOpen(false);
  };

  const filteredDocuments = allClusters.filter((item) =>
    item.cluster_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (allClustersLoading) {
    return <Skeleton variant="text" sx={{ width: '200px' }} />;
  }

  return (
    <>
      <Label onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
        {selectedCluster && selectedCluster?.cluster_name && selectedCluster?.cluster_name.length >= 1 ? (
          <>
            {formatString(selectedCluster?.cluster_name, 25)}{' '}
            <CancelTwoToneIcon
              style={{ fontSize: '18px', marginLeft: '12px' }}
              onClick={(e) => {
                e.stopPropagation();
                cancelClusterSelect();
              }}
            />
          </>
        ) : (
          <>
            Search from all documents <ArrowDropDownIcon />
          </>
        )}
      </Label>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Your Clusters</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To narrow down your search to a specific cluster, kindly choose one from the options below. Your search
            results will then be refined to only the documents within the selected cluster.
          </DialogContentText>
          {filteredDocuments && filteredDocuments.length >= 1 ? (
            <div style={{ marginTop: '20px' }}>
              <TextField
                id="outlined-basic"
                label="Search Clusters"
                variant="outlined"
                style={{ width: '100%' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <div style={{ maxHeight: '500px', height: '500px' }}>
                {filteredDocuments
                  .map((item, index) => (
                    <Card
                      key={index}
                      style={{
                        marginTop: '8px',
                        backgroundColor: selectedCluster && selectedCluster.id === item.id ? '#e3e3e3' : '',
                      }}
                    >
                      <CardActionArea onClick={(e) => selectCluster(item)}>
                        <CardContent
                          className="flex items-center"
                          style={{ border: '1px dashed rgb(205 210 222)', borderRadius: '12px', padding: '10px' }}
                        >
                          <img
                            src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/icons/folder_icon.svg"
                            alt="folder-icon"
                          />
                          <Typography style={{ marginLeft: '10px' }}>{item.cluster_name}</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ))
                  .reverse()}
              </div>
            </div>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelClusterSelect}>Cancel</Button>
          <Button type="submit" onClick={handleClose}>
            Select Cluster
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
