import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import useResponsive from '../../../hooks/useResponsive';
import { formatString } from '../../../helpers/formatString';

export default function HandleContextDrawer({ documentName, context }) {
  const [state, setState] = useState({
    right: false,
  });
  const isDesktop = useResponsive('up', 'md');

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const list = (anchor) => (
    <Box
      sx={{ width: isDesktop ? 500 : 360 }}
      role="presentation"
      onClick={stopPropagation}
      onKeyDown={stopPropagation}
    >
      <div style={{ padding: '20px' }}>
        <p className="font-semibold">{documentName}</p>
        <p className="mt-1 text-gray-600">The following context was used to answer your question</p>
      </div>

      <div style={{ border: 'solid gray 1px', margin: '20px', borderRadius: '6px' }}>
        {context &&
          context.map((item, index) => (
            <Accordion
              key={index}
              onClick={stopPropagation}
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                borderBottom: index === context.length - 1 ? '' : 'solid gray 1px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="text-gray-600"
                sx={{ width: isDesktop ? '100%' : '360px' }}
                onClick={stopPropagation}
              >
                Context {index + 1}
              </AccordionSummary>
              <AccordionDetails>{item}</AccordionDetails>
            </Accordion>
          ))}
      </div>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <div key={anchor}>
          <button type="submit" onClick={toggleDrawer(anchor, true)}>
            <span className="flex-1 w-3 truncate" style={{ borderBottom: 'dashed 1px #2563eb' }}>
              {formatString(documentName, 25)}
            </span>
          </button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </div>
      ))}
    </div>
  );
}
