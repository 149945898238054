import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import Avatar from '@mui/material/Avatar';
import { toast } from 'react-hot-toast';
import { removeResources, searchChat } from '../../../store/actions/search';
import MarkdownDisplay from './MarkdownDisplay';
import ResourceList from './ResourceList';
import SelectCluster from './SelectCluster';
import Label from '../../../components/label/Label';
import { formatString } from '../../../helpers/formatString';
import useResponsive from '../../../hooks/useResponsive';
import { checkUserAccess } from '../../../store/actions/user/checkAccess';

export default function Internal() {
  const dispatch = useDispatch();
  const searchOutput = useSelector((state) => state.search.searchOutput);
  const searchOutputLoading = useSelector((state) => state.search.searchOutputLoading);
  const searchOutputResources = useSelector((state) => state.search.searchOutputResources);
  const isMobileOrTablet = useResponsive('down', 'md');

  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [query, setQuery] = useState('');
  const [questionAsked, setQuestionAsked] = useState(false);
  const [answerGenerated, setAnswerGenerated] = useState(false);
  const [answer, setAnswer] = useState('');
  const [resources, setResources] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState({});

  useEffect(() => {
    setAnswerGenerated(true);
    setLoading(true);
    if (searchOutput && searchOutput?.success === true && searchOutput?.answer?.length >= 1) {
      setAnswerGenerated(false);
      setAnswer(searchOutput.answer);
      setLoading(false);
      setPrompt('');
    } else {
      setPrompt('');
      setAnswerGenerated(false);
      setAnswer(searchOutput);
      setLoading(false);
    }
  }, [searchOutput]);

  useEffect(() => {
    if (searchOutputResources && searchOutputResources.length >= 1) {
      setResources(searchOutputResources);
    }
  }, [searchOutputResources]);

  const handleButtonClick = async (e) => {
    const access = await checkUserAccess();

    if (!prompt || prompt.length < 2) {
      toast.error('Please ask a valid inquiry.');
    } else if (access && access.success === false) {
      toast.error(access.message);
    } else {
      dispatch(removeResources());
      setQuestionAsked(true);
      setLoading(true);
      setQuery(prompt);
      e.preventDefault();
      dispatch(searchChat(prompt, selectedCluster));
    }
  };

  const clearQuestions = () => {
    setQuestionAsked(false);
    setQuery('');
    setAnswer('');
  };
  return (
    <>
      <div className="relative" style={{ paddingTop: isMobileOrTablet ? '0px' : '70px' }}>
        <div className="max-w-4xl mx-auto text-center">
          <h1 style={{ marginTop: '14px' }} className="text-3xl font-bold text-gray-800 sm:text-4xl">
            Internal Data Search
          </h1>
          <p className="mt-1 text-gray-600">Retrieve answers by querying your internally uploaded documents</p>
        </div>
        {questionAsked ? (
          <>
            <div
              className="max-w-4xl mx-auto"
              style={{ height: '604px', maxHeight: '604px', overflowY: 'scroll', scrollbarWidth: 'none' }}
            >
              <ul className="mt-16 space-y-5">
                <li
                  style={{
                    maxWidth: '2xl',
                    marginLeft: 'auto',
                    display: 'flex',
                    justifyContent: 'end',
                    gap: '0.5rem',
                    gapX: '2',
                    padding: '20px',
                    '@media (minWidth: 640px)': { gapX: '4' },
                  }}
                >
                  <div style={{ flexGrow: '1', textAlign: 'end', marginBottom: '3' }}>
                    <div
                      style={{
                        display: 'inline-block',
                        backgroundColor: '#2563EB',
                        borderRadius: '0.5rem',
                        padding: '1rem',
                        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
                      }}
                    >
                      <p style={{ fontSize: '0.875rem', color: '#ffffff' }}>{query}</p>
                    </div>
                  </div>

                  <span
                    style={{
                      flexShrink: '0',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '38px',
                      height: '38px',
                      borderRadius: '50%',
                      backgroundColor: '#4B5563',
                    }}
                  >
                    <Avatar
                      alt="neutral-avatar"
                      src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/icons/neutral-glasses-person.jpg"
                    />
                  </span>
                </li>

                <li
                  style={{
                    display: 'flex',
                    padding: '20px',
                    gap: '0.5rem',
                    '@media (minWidth: 640px)': { gap: '1rem' },
                  }}
                >
                  <Avatar sx={{ backgroundColor: '#2563eb' }}>
                    <BubbleChartIcon style={{ color: 'white' }} />
                  </Avatar>

                  <div
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid #E5E7EB',
                      borderRadius: '0.375rem',
                      padding: '1rem',
                      marginTop: '0.375rem',
                    }}
                  >
                    <div style={{ marginBottom: '0.375rem', fontSize: '0.875rem', color: '#374151' }} className="prose">
                      {searchOutputLoading ? 'Loading...' : <MarkdownDisplay answer={answer} /> || ''}
                    </div>
                    {searchOutputLoading ? (
                      <></>
                    ) : (
                      <>
                        <div
                          style={{ marginTop: '10px', display: isMobileOrTablet ? '' : 'flex' }}
                          className="justify-between items-center"
                        >
                          <div>
                            <Label
                              style={{
                                backgroundColor: '#2563eb',
                                color: 'white',
                              }}
                            >
                              {formatString(
                                (selectedCluster && selectedCluster.cluster_name) || 'Answer from: All Documents',
                                30
                              )}
                            </Label>
                            <Label
                              style={{
                                marginLeft: isMobileOrTablet ? '0px' : '10px',
                                backgroundColor: '#2563eb',
                                color: 'white',
                                marginTop: isMobileOrTablet ? '5px' : '0px',
                                marginBottom: isMobileOrTablet ? '5px' : '0px',
                              }}
                            >
                              Internal Search
                            </Label>
                          </div>

                          <div>
                            <p style={{ fontSize: '12px' }}>Consider checking important information</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </li>
                {answer &&
                answer ===
                  'Sorry - The question you asked is not within my knowledge base. Please ask something else.' ? (
                  <></>
                ) : (
                  <>
                    <ResourceList searchOutputLoading={searchOutputLoading} resources={resources} />
                  </>
                )}
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="max-w-4xl mx-auto" style={{ height: '540px', maxHeight: '540px' }}>
              <ul className="mt-16 space-y-5">
                <li className="flex gap-x-2 sm:gap-x-4">
                  <Avatar sx={{ backgroundColor: '#2563eb' }}>
                    <BubbleChartIcon />
                  </Avatar>

                  <div className="w-full bg-white border border-gray-200 rounded-lg p-4 space-y-3">
                    <Label variant="filled" color="primary" style={{ backgroundColor: '#2563eb' }}>
                      Internal Data
                    </Label>
                    <h2 className="font-medium text-gray-800">Welcome, how can I help?</h2>
                    <div className="space-y-1.5">
                      <p className="mb-1.5 text-sm text-gray-800">
                        Explore any question within your uploaded documents, or refine your search by selecting a
                        specific cluster you've created.
                      </p>

                      <p className="mb-1.5 text-sm text-gray-800">Quick links...</p>
                      <ul className="mt-1.5 list-disc list-outside space-y-1.5 ps-3.5">
                        <li className="text-sm text-gray-800">
                          <Link to="/upload-documents" style={{ color: '#2563eb' }}>
                            Train your documents before asking any questions
                          </Link>
                        </li>

                        <li className="text-sm text-gray-800">
                          <Link to="/uploaded-documents" style={{ color: '#2563eb' }}>
                            View trained documents
                          </Link>
                        </li>

                        <li className="text-sm text-gray-800">
                          <Link to="/clusters" style={{ color: '#2563eb' }}>
                            Manage clusters
                          </Link>
                        </li>
                      </ul>
                      <p className="mb-1.5 text-sm text-gray-800" style={{ marginTop: '10px' }}>
                        For optimal and precise answers, we suggest organizing your documents into clusters and then
                        conducting searches within those clusters.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}

        <footer
          style={{ bottom: 0 }}
          className="max-w-4xl mx-auto sticky z-10 pt-2 pb-4 sm:pt-4 sm:pb-6 px-4 sm:px-6 lg:px-0"
        >
          <div className="relative">
            <textarea
              className="p-4 pb-12 block w-full rounded-lg text-sm"
              style={{ border: 'solid 1px gray' }}
              placeholder="Ask me anything..."
              disabled={answerGenerated}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />

            <div className="absolute bottom-px inset-x-px p-2 rounded-b-md bg-white">
              <div className="flex justify-between items-center">
                <div>
                  <button
                    onClick={clearQuestions}
                    type="button"
                    style={{ backgroundColor: 'gray' }}
                    className="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-lg text-white"
                  >
                    <AddIcon />
                  </button>
                </div>
                <div className="flex justify-center items-center">
                  <SelectCluster selectedCluster={selectedCluster} setSelectedCluster={setSelectedCluster} />
                  <button
                    type="button"
                    onClick={handleButtonClick}
                    style={{ backgroundColor: '#2563eb', marginLeft: '10px' }}
                    className="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-lg text-white"
                  >
                    <svg
                      className="flex-shrink-0 size-3.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
