import axios from 'axios';
import toast from 'react-hot-toast';
import { Auth } from 'aws-amplify';
import { NEW_DELETE_CLUSTER } from '../../API'; // Import the DELETE_CLUSTER endpoint
import { REMOVE_CLUSTER } from '../../types';

export const deleteCluster = (cluster) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userId = user?.username;
  const clusterId = cluster.cluster_id;

  const params = {
    email,
    userId,
    clusterId,
  };

  try {
    const response = await axios.delete(NEW_DELETE_CLUSTER, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params,
    });

    // console.log('res delete', response);
    if (response && response.data && response.data.success === true) {
      dispatch({
        type: REMOVE_CLUSTER,
        payload: clusterId,
      });
      toast.success('Cluster successfully deleted');
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    console.error('Error deleting cluster:', error);
    toast.error('Error deleting cluster. Please try again!!');
  }
};
