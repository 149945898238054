import { Auth } from 'aws-amplify';
import axios from 'axios';
import { NEW_UPLOAD_URL } from '../../API';

export const uploadDocumentsToS3 = async (fileTrainingOutput, files) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userid = user?.username.substring(0, 8).toLowerCase();

  const postData = {
    userid,
    email,
    files: fileTrainingOutput
      .filter((outputItem) => outputItem.success === true && files.some((file) => file.name === outputItem.name))
      .map((outputItem) => {
        const matchingFile = files.find((file) => file.name === outputItem.name);
        return { name: matchingFile.name, type: matchingFile.type };
      }),
  };

  console.log('---- ready to upload to s3');

  try {
    axios
      .post(NEW_UPLOAD_URL, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        console.log('---- s3 succesful');
        res.data.forEach(async (item, index) => {
          const fileToUpload = files.find((file) => file.name === item.name);
          const result = await fetch(item.uploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': files[index].type,
            },
            body: fileToUpload,
          });
        });
        return res.data;
      });
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};
