import {
  CLEAR_ALL,
  SEARCH_OUTPUT,
  SEARCH_LOADING,
  REMOVE_SEARCH_OUTPUTS,
  SEARCH_OUTPUT_RESOURCES,
  REMOVE_RESOURCES,
} from '../types';

const initialState = {
  searchOutputLoading: false,
  searchOutput: '',
  searchOutputResources: [],
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case REMOVE_RESOURCES:
      return {
        ...state,
        searchOutputResources: [],
      };
    case SEARCH_OUTPUT_RESOURCES:
      return {
        ...state,
        searchOutputResources: action.payload,
      };
    case SEARCH_LOADING:
      return {
        ...state,
        searchOutputLoading: action.payload,
      };
    case SEARCH_OUTPUT:
      return {
        ...state,
        // searchOutputLoading: false,
        searchOutput: action.payload,
      };
    case REMOVE_SEARCH_OUTPUTS:
      return {
        ...state,
        searchOutput: '',
      };
    case CLEAR_ALL:
      return {
        ...state,
        searchOutputLoading: false,
        searchOutput: '',
      };
    default:
      return state;
  }
}
