export const MAIN_API = 'https://5net7sfui7.execute-api.us-east-1.amazonaws.com/default';
export const FILE_HANDLER_API = 'https://52yidq61gf.execute-api.us-east-1.amazonaws.com/default';

export const GET_AUTH_USER_API = MAIN_API.concat('/user');
export const SEARCH_API = MAIN_API.concat('/search');
export const TRAIN_API = MAIN_API.concat('/train');

export const UPLOAD_DOCUMENT_TO_EXTERNAL_DB_API = MAIN_API.concat('/documents');
export const GET_DOCUMENT_FROM_EXTERNAL_DB_API = MAIN_API.concat('/documents');
export const DELETE_DOCUMENTS_FROM_EXTERNAL_DB_API = MAIN_API.concat('/documents');

export const GET_DOCUMENT_FROM_EXTERNAL_DB_API_FILTERED = MAIN_API.concat('/documents/filtered');
export const GET_DOCUMENT_UPLOAD_URL = FILE_HANDLER_API.concat('/upload-url');
export const DOWNLOAD_FILE = FILE_HANDLER_API.concat('/download');
export const COMPANY_USERS_API = MAIN_API.concat('/company-users');
export const DELETE_S3_DOCUMENT_API = FILE_HANDLER_API.concat('/delete-documents');
export const UPDATE_AUTH_USER_API = MAIN_API.concat('/user');

export const TRAIN_V2 = MAIN_API.concat('/train-v2');
export const REMOVE_PINECONE_TRAINING = MAIN_API.concat('/pinecone-remove-training');
export const EXTERNAL_DB_UPLOAD = MAIN_API.concat('/document-entry');
export const DELETE_EXTERNAL_DB_API = MAIN_API.concat('/remove-document');

export const NEW_UPLOAD_URL = FILE_HANDLER_API.concat('/upload-url');
export const NEW_DOWNLOAD_URL = FILE_HANDLER_API.concat('/download');
export const NEW_GET_ALL_CLUSTERS = MAIN_API.concat('/clusters');
export const NEW_GET_CLUSTER = MAIN_API.concat('/cluster');
export const NEW_CREATE_CLUSTER = MAIN_API.concat('/cluster');
export const NEW_DELETE_CLUSTER = MAIN_API.concat('/cluster');
export const NEW_UPDATE_CLUSTER = MAIN_API.concat('/cluster');
export const NEW_CHECK_USER_ACCESS = MAIN_API.concat('/user/user-access');

export const INTERNAL_SEARCH = MAIN_API.concat('/internal-search');
export const ALL_DOCUMENTS = MAIN_API.concat('/all-documents');
export const DOCUMENTS = MAIN_API.concat('/documents');
export const DOCUMENT_ENTRY_CHECK = MAIN_API.concat('/document-entry-check');
export const DOCUEMNT_ENTRY = MAIN_API.concat('/document-entry');
export const TRAIN_AI = MAIN_API.concat('/train-ai');
export const USER = MAIN_API.concat('/user');
export const USER_LOGIN = MAIN_API.concat('/login');
export const POST_REGISTRATION = MAIN_API.concat('/post-registration');
