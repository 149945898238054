export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_OUTPUT = 'SEARCH_OUTPUT';
export const REMOVE_SEARCH_OUTPUTS = 'REMOVE_SEARCH_OUTPUTS';

export const EXTERNAL_DB_USER = 'EXTERNAL_DB_USER';

export const TRAINING_IN_PROGRESS = 'TRAINING_IN_PROGRESS';
export const TRAINING_COMPLETED = 'TRAINING_COMPLETED';
export const CLEAR_TRAINING = 'CLEAR_TRAINING';

export const TRAINING_STATUS = 'TRAINING_STATUS';

export const UPLOADED_DOCUMENTS = 'UPLOADED_DOCUMENTS';
export const GET_COMPANY_DOCUMENTS = 'GET_COMPANY_DOCUMENTS';
export const GET_ALL_DOCUMENTS = 'GET_ALL_DOCUMENTS';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const DOCUMENTS_DELETED = 'DOCUMENTS_DELETED';
export const DOCUMENT_DELETION_IN_PROGRESS = 'DOCUMENT_DELETION_IN_PROGRESS';
export const CREATE_USER_IN_PROGRESS = 'CREATE_USER_IN_PROGRESS';
export const CLEAR_ALL = 'CLEAR_ALL';

export const GET_EXTERNAL_DB_USER = 'GET_EXTERNAL_DB_USER';
export const SEARCH_OUTPUT_RESOURCES = 'SEARCH_OUTPUT_RESOURCES';
export const REMOVE_RESOURCES = 'REMOVE_RESOURCES';

export const GET_ALL_CLUSTERS = 'GET_ALL_CLUSTERS';
export const GET_CLUSTER = 'GET_CLUSTER';
export const CLUSTER_CREATED = 'CLUSTER_CREATED';
export const REMOVE_CLUSTER = 'REMOVE_CLUSTER';
export const CLUSTER_UPDATED = 'CLUSTER_UPDATED';
