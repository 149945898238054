import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import Sidebar from './Sidebar';
import Header from './header';
import useResponsive from '../../hooks/useResponsive';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [open, setOpen] = useState(false);
  const isMobileOrTablet = useResponsive('down', 'md');
  const isBelow1200 = useResponsive('down', 'lg');

  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: isMobileOrTablet ? APP_BAR_MOBILE + 24 : APP_BAR_DESKTOP,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: 10,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <StyledRoot>
      {isBelow1200 && <Header onOpenNav={() => setOpen(true)} />}{' '}
      {/* Ensure Header is displayed on mobile and tablet */}
      <Sidebar openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
