import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Card, CardContent, CardActionArea, Typography } from '@mui/material';
import FileTypeImage3D from '../../helpers/FileTypeImage3D';

export default function DocumentSelectDialog({ allDocuments, selectedDocuments, setSelectedDocuments }) {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCancel = () => {
    setSelectedDocuments([]);
    setOpen(false);
  };

  const selectDocuments = () => {
    setOpen(false);
  };

  const handleAddUpload = (item) => {
    const isSelected = selectedDocuments.some((doc) => doc.id === item.id);
    if (isSelected) {
      setSelectedDocuments(selectedDocuments.filter((doc) => doc.id !== item.id));
    } else {
      setSelectedDocuments([...selectedDocuments, item]);
    }
  };
  // Function to filter documents based on search query
  const filteredDocuments = allDocuments.filter((item) =>
    item.document_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div
        style={{
          padding: '2rem',
          border: '1px dashed rgb(103, 114, 138)',
          borderRadius: '6px',
          marginTop: '15px',
        }}
        className="flex justify-center items-center cursor-pointer"
      >
        <Button type="submit" variant="outlined" onClick={handleClickOpen}>
          Add Documents to this cluster
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select from your uploaded documents</DialogTitle>
        <DialogContent>
          <Box style={{ marginTop: '20px' }}>
            <TextField
              id="outlined-basic"
              label="Search Documents"
              variant="outlined"
              style={{ width: '100%' }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <Box style={{ marginTop: '15px' }}>
              {filteredDocuments.length >= 1 ? (
                <div style={{ height: '450px', maxHeight: '450px', overflowY: 'scroll' }}>
                  {filteredDocuments
                    .map((item) => (
                      <Card
                        key={item.id}
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <CardActionArea onClick={(e) => handleAddUpload(item)}>
                          <CardContent
                            style={{
                              paddingTop: '14px',
                              paddingBottom: '14px',
                              borderRadius: '12px',
                              backgroundColor: selectedDocuments.some((doc) => doc.id === item.id)
                                ? '#dddddd'
                                : 'white',
                              border: '1px dashed rgb(103, 114, 138)',
                              cursor: 'pointer',
                            }}
                          >
                            <Box>
                              <Typography>{item.document_name}</Typography>
                              <Box className="flex items-center">
                                <div style={{ width: '5%' }}>
                                  <FileTypeImage3D name={item.document_name || ''} />
                                </div>
                                <Typography sx={{ fontSize: '14px', color: 'gray', marginLeft: '6px' }}>
                                  {item.document_type}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    ))
                    .reverse()}
                </div>
              ) : (
                <DialogContentText sx={{ marginTop: '15px' }}>No documents match your search.</DialogContentText>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={selectDocuments}>
            Select Documents
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
