import { Auth } from 'aws-amplify';
import { getUserInfo } from '../../../store/actions/user/unAuthUser';

export async function signInUser(email, password, dispatch) {
  try {
    // Check if user exist in cognito
    const user = await Auth.signIn(email, password);
    if (user) {
      return { success: true, user };
    }
    return { success: false, user: null, userInfo: null, error: { message: 'User Not Found! Please try again' } };
  } catch (error) {
    // console.log('error signing in', error);
    return { success: false, error };
  }
}
