import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import useResponsive from '../../../../hooks/useResponsive';

export default function BCMASadvertisement() {
  const isDesktop = useResponsive('up', 'md');
  return (
    <Box>
      <Card sx={{ background: 'linear-gradient(135deg,  #0c3a57, #014459)', color: 'white' }}>
        <CardContent>
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                className="bouncing-image"
                src="https://images.ctfassets.net/00vue9s2mk1n/5jAX4Uo8VnCmKDrQvpdkLx/39325d2798fe8601d05edeb97667eda0/BCMAS.svg"
                alt="bcmas-seal"
                width="50%"
              />
            </Grid>
            <Grid item md={6} sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h3">
                Become a Board Certified Medical Affairs Specialist (BCMAS
                <span style={{ fontSize: '16px', paddingBottom: '10px' }}>™</span>)
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                The best path you can choose to enter the MSL/Medical Affairs industry or to elevate your existing
                career as an MSL.
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button
                  sx={{
                    backgroundColor: 'white',
                    fontSize: '18px',
                    color: 'black',
                    '&:hover': { backgroundColor: '#dfdfdf' },
                    mr: 2,
                  }}
                  target="_blank"
                  fullWidth={!isDesktop}
                  size="large"
                  href="https://medicalaffairsspecialist.org/"
                >
                  Learn more
                </Button>

                <Button
                  fullWidth={!isDesktop}
                  sx={{
                    backgroundColor: 'white',
                    fontSize: '18px',
                    color: 'black',
                    '&:hover': { backgroundColor: '#dfdfdf' },
                    mt: isDesktop ? 0 : 2,
                  }}
                  target="_blank"
                  href="https://www.acmamarketplace.org/certification/bcmas"
                >
                  Enroll Today
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
