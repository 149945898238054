import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDocuments } from '../store/actions/documents/getAllDocuments';

export function useGetAllDocuments() {
  const allDocuments = useSelector((state) => state.documents.allDocuments);
  const allDocumentsLoading = useSelector((state) => state.documents.allDocumentsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    async function companyUsers() {
      try {
        await dispatch(getAllDocuments());
      } catch (err) {
        console.log(err);
      }
    }
    companyUsers();
  }, []);

  return { allDocuments, allDocumentsLoading };
}
