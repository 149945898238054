import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  CircularProgress,
  Skeleton,
  Card,
  CardContent,
  TextField,
  IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useResponsive from '../../../../hooks/useResponsive';
import { useGetCluster } from '../../../../hooks/useGetCluster';
import { withAuth } from '../../../../components/HOC/withAuth';
import FileTypeImage3D from '../../../../helpers/FileTypeImage3D';
import DocumentSelectDialog from '../../../../components/dialogs/DocumentSelectDialog';
import { useGetAllDocuments } from '../../../../hooks/useGetAllDocuments';
import NoCluster from './NoCluster';
import { updateCluster } from '../../../../store/actions/clusters/updateCluster';
import { checkUserAccess } from '../../../../store/actions/user/checkAccess';

function EditCluster() {
  const { clusterId } = useParams();
  const isDesktop = useResponsive('up', 'md');
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const { allDocuments, allDocumentsLoading } = useGetAllDocuments();
  const { selectedCluster, selectedClusterLoading } = useGetCluster(clusterId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedCluster && selectedCluster.cluster && selectedCluster.cluster.documents) {
      setSelectedDocuments(selectedCluster.cluster.documents);
      setClusterName(selectedCluster?.cluster?.cluster_info?.cluster_name);
    }
  }, [selectedCluster]);

  const handleRemoveItem = (item) => {
    setSelectedDocuments(selectedDocuments.filter((doc) => doc !== item));
  };

  const handleUpdateCluster = async () => {
    if (!clusterName || clusterName.length === 0) {
      toast.error('Cluster name is missing');
    } else if (!selectedDocuments || selectedDocuments.length === 0) {
      toast.error('Please select documents to create cluster');
    } else {
      const access = await checkUserAccess();
      if (access && access.success === false) {
        toast.error(access.message);
      } else {
        dispatch(updateCluster(clusterId, clusterName, selectedDocuments));
      }
    }
  };

  if (selectedClusterLoading || allDocumentsLoading) {
    return (
      <Container sx={{ padding: '0px' }} className="p-5 lg:py-14">
        <Box sx={{ width: '100%', mt: 4, pb: 10 }}>
          <Grid spacing={3} container className="flex justify-center align-center">
            <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{ pt: 4, pb: 3, display: isDesktop ? 'flex' : 'block' }}
                className=" justify-between align-center"
              >
                <Box>
                  <Typography variant="h4">Cluster</Typography>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Box>
              </Box>
              <CircularProgress />
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }

  if (selectedCluster && selectedCluster.success === false) {
    return <NoCluster />;
  }

  return (
    <>
      <Container sx={{ padding: '0px' }} className="p-5 lg:py-14">
        <Box sx={{ width: '100%', mt: 4, pb: 10 }}>
          <Grid spacing={3} container className="flex justify-center align-center">
            <Grid
              item
              md={12}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: isDesktop ? '24px' : '0px',
              }}
            >
              <Box sx={{ pb: 3 }}>
                <Typography variant="h4">Cluster</Typography>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" to="/clusters">
                    Clusters
                  </Link>

                  <Typography color="text.primary">{clusterName}</Typography>
                </Breadcrumbs>
              </Box>
            </Grid>
          </Grid>

          <Card>
            <CardContent sx={{ p: isDesktop ? 5 : 2.5 }}>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item md={12} sx={{ width: '100%' }}>
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Cluster Name"
                    variant="outlined"
                    value={clusterName}
                    onChange={(e) => setClusterName(e.target.value)}
                  />
                </Grid>
                <Grid item md={12}>
                  <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                    {selectedDocuments.map((item) => (
                      <Card
                        key={item.id}
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <CardContent
                          style={{
                            paddingTop: '14px',
                            paddingBottom: '14px',
                            borderRadius: '12px',
                            border: '1px dashed rgb(103, 114, 138)',
                            cursor: 'pointer',
                          }}
                        >
                          <Box>
                            <Typography>{item.document_name}</Typography>
                            <Box className="flex justify-between items-center">
                              <div className="flex items-center">
                                <div style={{ width: isDesktop ? '20%' : '20%' }}>
                                  <FileTypeImage3D name={item.document_name || ''} />
                                </div>
                                <Typography sx={{ fontSize: '14px', color: 'gray', marginLeft: '6px' }}>
                                  {item.document_type}
                                </Typography>
                              </div>
                              <div>
                                <IconButton aria-label="delete" onClick={(e) => handleRemoveItem(item)}>
                                  <CancelTwoToneIcon />
                                </IconButton>
                              </div>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  </div>

                  <DocumentSelectDialog
                    allDocuments={allDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                    selectedDocuments={selectedDocuments}
                  />
                </Grid>
                <Grid item md={12} className="flex justify-end">
                  {selectedDocuments && selectedDocuments.length >= 1 ? (
                    <>
                      <button type="submit" className="custom-button-dark" onClick={handleUpdateCluster}>
                        Update Cluster
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
}

export default withAuth(EditCluster);
