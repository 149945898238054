import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FileTypeImage3D from '../../../helpers/FileTypeImage3D';
import { formatString } from '../../../helpers/formatString';
import useResponsive from '../../../hooks/useResponsive';
import { getTrainedDocuments } from '../../../store/actions/documents/getDocuments';
import TableRowsLoader from './TableRowsLoader';
import DeleteDocument from './DeleteDocument';
import DocumentFilters from './DocumentFilters';
import DownloadFile from './DownloadFile';

const headCells = [
  {
    id: 'document_name',
    numeric: true,
    disablePadding: true,
    label: 'Document Name',
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'User',
  },
  {
    id: 'document_type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'date_created',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ViewTrainedDocuments() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [documents, setDocuments] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const isDesktop = useResponsive('up', 'md');
  const isMobileOrTablet = useResponsive('down', 'md');

  const documentDeletionInProgress = useSelector((state) => state.documents.documentDeletionInProgress);
  const trainedDocuments = useSelector((state) => state.documents.trainedDocuments);
  const trainedDocumentsLoading = useSelector((state) => state.documents.trainedDocumentsLoading);

  React.useEffect(() => {
    dispatch(getTrainedDocuments(page, filters));
  }, [page, filters]);

  React.useEffect(() => {
    setDocuments(trainedDocuments.documents);
  }, [trainedDocuments]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = documents && documents.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleFilterDispatch = (filterPayload) => {
    setFilters(filterPayload);
    dispatch(getTrainedDocuments(page, filterPayload));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const visibleRows = React.useMemo(() => {
    if (!documents) {
      return [];
    }
    return documents;
  }, [documents, order, orderBy, page, rowsPerPage]);

  if (trainedDocumentsLoading || documentDeletionInProgress) {
    return <TableRowsLoader rowsNum={8} documentDeletionInProgress={documentDeletionInProgress} />;
  }

  return (
    <Box sx={{ width: '100%', mt: isMobileOrTablet ? 0 : 4, pb: 10 }}>
      <Grid spacing={3} container className="flex justify-center align-center">
        <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{ pt: 0, pb: isMobileOrTablet ? 0 : 3, display: isDesktop ? 'flex' : 'block' }}
            className=" justify-between align-center"
          >
            <Box>
              <Typography variant="h4">Trained Documents</Typography>
              <Typography sx={{ color: 'gray' }}>Manage and monitor your trained documents below</Typography>
            </Box>
            <Box sx={{ mt: isDesktop ? 0 : 2 }}>
              <Button variant="outlined" onClick={(e) => navigate('/upload-documents')}>
                <CloudUploadIcon style={{ marginRight: '8px' }} />
                Upload
              </Button>
            </Box>
          </Box>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <DocumentFilters page={page} onFilterApply={handleFilterDispatch} />

            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                <EnhancedTableHead
                  numSelected={(selected && selected.length) || 0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={(documents && documents.length) || 0}
                />
                <TableBody>
                  {visibleRows &&
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.document_name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleFileInfo(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.document_name}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                              onClick={(event) => handleClick(event, row)}
                            /> */}
                          </TableCell>

                          <Tooltip title={row.document_name}>
                            <TableCell sx={{ p: 2 }} component="th" id={labelId} scope="row" padding="none">
                              <Box className="flex align-center">
                                <FileTypeImage3D name={row.document_name} customWidth="30%" />
                                <Box sx={{ pl: 1.4 }}>{formatString(row.document_name, 20)}</Box>
                              </Box>
                            </TableCell>
                          </Tooltip>

                          <TableCell align="right">
                            <Typography sx={{ fontSize: '14px' }}>{formatString(row.name, 15)}</Typography>
                            {formatString(row.email, 24)}
                          </TableCell>
                          {/* <TableCell align="right">
                            <Typography sx={{ fontSize: '14px' }}>{row.document_size}</Typography>
                          </TableCell> */}
                          <TableCell align="right">
                            <Typography sx={{ fontSize: '14px' }}>{row.document_type}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography sx={{ fontSize: '14px' }}>{row.date_created}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <DownloadFile file={row} />
                            <DeleteDocument file={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[8]}
              component="div"
              count={trainedDocuments ? trainedDocuments.total : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
          {/* <SelectedMoreInfoDocument document={moreInfoDocument} setDocument={setMoreInfoDocument} /> */}
        </Grid>
      </Grid>
    </Box>
  );
}
