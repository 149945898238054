import ReactMarkdown from 'react-markdown';

export default function MarkdownDisplay({ answer }) {
  return (
    <>
      <ReactMarkdown
        components={{
          h1: ({ node, children, ...props }) => (
            <h1 style={{ color: 'black', fontSize: '32px', fontWeight: 800 }} {...props}>
              {children}
            </h1>
          ),
          h2: ({ node, children, ...props }) => (
            <h2 style={{ color: 'black', fontSize: '24px', fontWeight: 800 }} {...props}>
              {children}
            </h2>
          ),
          h3: ({ node, children, ...props }) => (
            <h3 style={{ color: 'black', fontSize: '18px', fontWeight: 800 }} {...props}>
              {children}
            </h3>
          ),
          h4: ({ node, children, ...props }) => (
            <h4 style={{ color: 'black', fontSize: '16px' }} {...props}>
              {children}
            </h4>
          ),
          h5: ({ node, children, ...props }) => (
            <h5 style={{ color: 'black', fontSize: '14px' }} {...props}>
              {children}
            </h5>
          ),
          p: ({ node, ...props }) => <p style={{ fontSize: '14px', lineHeight: '1.5' }} {...props} />,
          strong: ({ node, ...props }) => <strong style={{ fontWeight: 'bold' }} {...props} />,
          ul: ({ node, ...props }) => (
            <ul
              style={{
                display: 'block',
                listStyleType: 'disc',
                paddingInlineStart: '40px',
              }}
              {...props}
            />
          ),
          ol: ({ node, ...props }) => (
            <ol
              style={{
                display: 'block',
                listStyleType: 'decimal',
                paddingInlineStart: '40px',
              }}
              {...props}
            />
          ),
        }}
      >
        {answer}
      </ReactMarkdown>
    </>
  );
}
