import { IconButton } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useDispatch } from 'react-redux';
import { downloadFile } from '../../../store/actions/documents/download';

export default function DownloadFile({ file }) {
  const dispatch = useDispatch();
  const handleDownload = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(downloadFile(file?.document_name));
  };
  return (
    <IconButton onClick={handleDownload}>
      <CloudDownloadIcon />
    </IconButton>
  );
}
