import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Card, IconButton, Typography } from '@mui/material';
import FileTypeImage3D from '../../../../helpers/FileTypeImage3D';
import { formatString } from '../../../../helpers/formatString';
import getFileSize from '../../../../helpers/getFileSize';

export default function UploadedFileList({ file, onDelete, toggleDrawer }) {
  return (
    <Box sx={{ pb: 0.4, pt: 1 }}>
      <Card sx={{ border: 'solid 2px gray' }}>
        <Box className="flex justify-between" sx={{ p: 2 }}>
          <Box className="flex align-center">
            <FileTypeImage3D name={file.file.name} customWidth="80%" />
            <Box sx={{ pl: 1 }}>
              <Typography>{formatString(file.file.name, 20)}</Typography>
              <Typography sx={{ fontSize: '12px' }}>{getFileSize(file.file.size)}</Typography>
            </Box>
          </Box>
          <IconButton
            className="flex space-between"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onDelete(file.file);
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Card>
    </Box>
  );
}
