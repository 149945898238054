import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

export default function TableRowsLoader({ rowsNum, documentDeletionInProgress }) {
  const visibleRows = Array.from({ length: rowsNum }, (_, index) => index); // Generate an array of indices from 0 to rowsNum - 1

  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      <Grid spacing={3} container className="flex justify-center align-center">
        <Grid item md={11} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ pt: 4, pb: 3 }} className="flex justify-between align-center">
            <Box>
              <Typography variant="h4">Uploaded Documents</Typography>
              <Typography sx={{ color: 'gray' }}>Manage and monitor your uploaded documents below</Typography>
            </Box>
            <Box>
              <Button variant="contained" disabled>
                <CloudUploadIcon style={{ marginRight: '8px' }} />
                Upload
              </Button>
            </Box>
          </Box>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Box sx={{ p: 2, borderRadius: '15px' }} className="flex justify-between align-center">
              <Box sx={{ width: '30%', pr: 2 }}>
                <form noValidate>
                  <TextField
                    id="outlined-basic"
                    label="Search"
                    type="search"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton disabled>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled
                  />
                </form>
              </Box>
              <Box className="flex justify-center align-center">
                <Button endIcon={<KeyboardArrowDownIcon />} disabled>
                  Users
                </Button>
                <Button sx={{ ml: 2 }} disabled endIcon={<KeyboardArrowDownIcon />}>
                  Select Date
                </Button>
                <Button sx={{ ml: 2 }} disabled endIcon={<KeyboardArrowDownIcon />}>
                  Types
                </Button>
              </Box>
            </Box>
            {/* <Box sx={{ p: 2, borderRadius: '15px' }} className="flex">
              <Box>
                <FormControl sx={{ m: 1, width: 300, backgroundColor: '#f4f6f8' }}>
                  <InputLabel id="demo-multiple-checkbox-label">User</InputLabel>
                  <Select
                    disabled
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        <CheckBox />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: '100%', pr: 2 }}>
                <FormControl fullWidth sx={{ m: 1, backgroundColor: '#f4f6f8' }}>
                  <OutlinedInput
                    disabled
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Box> */}

            <TableContainer>
              {documentDeletionInProgress ? (
                <>
                  <Alert sx={{ backgroundColor: '#fff0b5' }} severity="warning">
                    Document deletion in progress. Please wait
                  </Alert>
                </>
              ) : (
                <></>
              )}
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <TableBody>
                  {visibleRows.map((index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ cursor: 'pointer' }}>
                        <TableCell padding="checkbox">
                          <Checkbox disabled />
                        </TableCell>
                        <TableCell sx={{ p: 2 }} component="th" id={labelId} scope="row" padding="none">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
