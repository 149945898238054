import axios from 'axios';
import { Auth } from 'aws-amplify';
import { GET_CLUSTER } from '../../types';
import { NEW_GET_CLUSTER } from '../../API';

export const getCluster = (clusterId) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  const userId = user?.username;

  try {
    const params = {
      email,
      userId,
      clusterId,
    };

    const res = await axios.get(NEW_GET_CLUSTER, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params,
    });
    // console.log('res data', res.data);
    if (res && res.data && res.data.success === true) {
      dispatch({
        type: GET_CLUSTER,
        payload: res.data,
      });
    }
    dispatch({
      type: GET_CLUSTER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CLUSTER,
      payload: [],
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
