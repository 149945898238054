import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
//
import Page404 from './pages/Page404';
import Home from './pages/unauth/home';
import LoginPage from './pages/authentication/LoginPage';
import ForgotPassword from './pages/authentication/forgotPassword';
import WhyMedAffairsAI from './pages/unauth/whyMedAffairsAI';
import Benefits from './pages/unauth/benefits';
import QuestionsAnswersPage from './pages/unauth/questions';
import Demo from './pages/unauth/demo';

import Main from './pages/auth/main';
import DashboardLayout from './layouts/dashboard';
import External from './pages/auth/external';
import Internal from './pages/auth/internal';
import UploadedDocuments from './pages/auth/uploadedDocuments';
import Cluster from './pages/auth/cluster/list';
import Upload from './pages/auth/upload';
import CreateCluster from './pages/auth/cluster/create';
import EditCluster from './pages/auth/cluster/edit';
import ContactUs from './pages/auth/contactUs';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/why-medaffairs-ai', element: <WhyMedAffairsAI /> },
    { path: '/benefits', element: <Benefits /> },
    { path: '/questions', element: <QuestionsAnswersPage /> },
    { path: '/demo', element: <Demo /> },

    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      element: <DashboardLayout />,
      children: [
        { path: '/app', element: <Main /> },
        { path: '/external', element: <External /> },
        { path: '/internal', element: <Internal /> },
        { path: '/upload-documents', element: <Upload /> },
        { path: '/uploaded-documents', element: <UploadedDocuments /> },
        { path: '/clusters', element: <Cluster /> },
        { path: '/create-cluster', element: <CreateCluster /> },
        { path: '/cluster/:clusterId', element: <EditCluster /> },
        { path: '/contact-us', element: <ContactUs /> },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
