import { useDispatch } from 'react-redux';
import HandleContextDrawer from './HandleContextDrawer';
import { downloadFile } from '../../../store/actions/documents/download';
import useResponsive from '../../../hooks/useResponsive';

export default function ResourceList({ searchOutputLoading, resources }) {
  const dispatch = useDispatch();
  const isMobileOrTablet = useResponsive('down', 'md');

  const handleDownload = (e, documentName) => {
    e.preventDefault();
    // console.log('documentName to download', documentName);
    dispatch(downloadFile(documentName));
  };

  return (
    <>
      <div
        style={{
          marginTop: '0px',
          marginBottom: '4rem',
          display: 'flex',
          gap: '0.5rem',
        }}
      >
        {searchOutputLoading === false && resources && resources.length > 0 ? (
          <div style={{ marginLeft: '65px', marginRight: '65px' }} className="w-3/4 sm:w-full md:w-2/4 lg:w-2/4">
            <ul className="flex flex-col justify-end text-start -space-y-px">
              {resources.map((resource, index) => (
                <li
                  key={index}
                  className="flex items-center gap-x-2 p-3 text-sm bg-white border text-gray-800 first:rounded-t-lg first:mt-0 last:rounded-b-lg"
                >
                  {Object.entries(resource).map(([documentName, contexts]) => (
                    <div className="w-full flex justify-between truncate" key={documentName}>
                      <HandleContextDrawer documentName={documentName} context={contexts} />

                      <button
                        onClick={(e) => handleDownload(e, documentName)}
                        type="button"
                        className="flex items-center gap-x-2 text-gray-500 hover:text-blue-500 whitespace-nowrap"
                      >
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                          <polyline points="7 10 12 15 17 10" />
                          <line x1="12" x2="12" y1="15" y2="3" />
                        </svg>
                        {isMobileOrTablet ? <></> : <span>Download</span>}
                      </button>
                    </div>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
