import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExterenalDbUser } from '../store/actions/user/externalDbUser';

export function useGetExternalUser() {
  const externalDbUser = useSelector((state) => state.user.externalDbUser);
  const externalUserLoading = useSelector((state) => state.user.externalDbUserLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    async function companyUsers() {
      try {
        await dispatch(getExterenalDbUser());
      } catch (err) {
        console.log(err);
      }
    }
    companyUsers();
  }, []);

  return { externalDbUser, externalUserLoading };
}
