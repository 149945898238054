import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';

export default function FileTypeImage3D({ name, customWidth }) {
  const isPDF = name && name.toLowerCase().includes('.pdf');
  const isDOCX = name && name.toLowerCase().includes('.docx');
  const isSpreadsheet = name && name.toLowerCase().includes('.xls');
  const isCsv = name && name.toLowerCase().includes('.csv');
  const isTxt = name && name.toLowerCase().includes('.txt');
  const isPng = name && name.toLowerCase().includes('.png');
  const isJpg = name && name.toLowerCase().includes('.jpg');
  const isJpeg = name && name.toLowerCase().includes('.jpeg');

  if (isPng || isJpg || isJpeg) {
    return (
      <div>
        <img
          src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/imgFile.svg"
          alt="img-icon"
          width="36px"
          height="36px"
        />
      </div>
    );
  }

  if (isPDF) {
    return (
      <div>
        <img
          src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/pdfFile.svg"
          alt="pdf-icon"
          width="36px"
          height="36px"
        />
      </div>
    );
  }

  if (isTxt) {
    return (
      <div>
        <img
          src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/txt-file.svg"
          alt="txt-icon"
          width="36px"
          height="36px"
        />
      </div>
    );
  }

  if (isDOCX) {
    return (
      <div>
        <ArticleIcon style={{ fontSize: '40px' }} />
      </div>
    );
  }

  if (isSpreadsheet) {
    return (
      <div>
        <img
          src={'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/spreadsheet.svg'}
          alt="Spreadsheet"
          width="36px"
          height="36px"
        />
      </div>
    );
  }

  if (isCsv) {
    return (
      <div>
        <img
          src={'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/spreadsheet.svg'}
          alt="Spreadsheet"
          width="36px"
          height="36px"
        />
      </div>
    );
  }
}
