import { Auth } from 'aws-amplify';
import axios from 'axios';
import { NEW_CHECK_USER_ACCESS } from '../../API';

export async function checkUserAccess() {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userId = user?.username;

  try {
    // Make an API call to your external database to retrieve user info
    const response = await axios.get(NEW_CHECK_USER_ACCESS, {
      params: {
        email,
        userId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    // console.log('checking user access - ', response.data);
    if (response.status === 200) {
      return response.data;
    }

    return { success: false, message: 'Invalid Access! Please contact us for help' };
  } catch (error) {
    // console.log('error getting user access info', error);
    return { success: false, message: 'Invalid Access! Please contact us for help' };
  }
}
