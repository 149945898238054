import { Auth } from 'aws-amplify';
import axios from 'axios';
import { REMOVE_PINECONE_TRAINING } from '../../API';

export const removeFailedChunksFromPinecone = async (fileTrainingOutput) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userid = user?.username.substring(0, 8).toLowerCase();
  const files = fileTrainingOutput.filter((outputItem) => outputItem.success === false);

  // console.log('---- files that need removal', files);

  if (!files || files.length === 0) {
    return null;
  }
  const postData = {
    userid,
    email,
    files,
  };

  try {
    axios
      .post(REMOVE_PINECONE_TRAINING, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        console.log('removed', res.data);
        return res.data;
      });
    return null;
  } catch (err) {
    console.log('---- error when removing pinecone chunks', err);
    return null;
  }
};
