import axios from 'axios';
import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import {
  SEARCH_OUTPUT,
  SEARCH_LOADING,
  REMOVE_SEARCH_OUTPUTS,
  SEARCH_OUTPUT_RESOURCES,
  REMOVE_RESOURCES,
} from '../types';
import { checkUserAccess } from './user/checkAccess';
import { INTERNAL_SEARCH } from '../API';

// const getResources = async (contextObj) => {
//   const firstThreeDocuments = contextObj.slice(0, 3);
//   const uniqueDocumentNames = [...new Set(firstThreeDocuments.map((obj) => obj.document_name))];
//   return uniqueDocumentNames;
// };

// const getResources = async (contextObj) => {
//   // Get the first 3 objects from the array
//   const firstThree = contextObj.slice(0, 3);

//   // Initialize an object to store contexts based on document_name
//   const contextMap = {};

//   // Iterate over the first 3 objects to accumulate contexts
//   firstThree.forEach((obj) => {
//     const documentName = obj.document_name;
//     const { context } = obj;
//     if (documentName) {
//       // If documentName already exists, append the context
//       contextMap[documentName].push(context);
//     } else {
//       // If documentName doesn't exist, create a new entry
//       contextMap[documentName] = [context];
//     }
//   });

//   // Construct the final array of unique objects
//   const uniqueObjects = Object.entries(contextMap).map(([documentName, contexts]) => ({
//     documentName,
//     context: contexts.join(' '), // Combine contexts
//   }));

//   return uniqueObjects;
// };

const getResources = async (contextObj) => {
  // Get the first 3 objects from the array
  const firstThree = contextObj.slice(0, 3);

  // Initialize an object to store contexts based on document_name
  const contextMap = {};

  // Iterate over the first 3 objects to accumulate contexts
  firstThree.forEach((obj) => {
    const documentName = obj.document_name;
    const { context } = obj;
    if (documentName) {
      // If document_name already exists, append the context
      if (Object.prototype.hasOwnProperty.call(contextMap, documentName)) {
        contextMap[documentName].push(context);
      } else {
        // If documentName doesn't exist, create a new entry with an array containing the context
        contextMap[documentName] = [context];
      }
    }
  });

  // Convert the context map into an array of objects
  const uniqueObjects = Object.entries(contextMap).map(([documentName, contexts]) => ({
    [documentName]: contexts,
  }));

  return uniqueObjects;
};

export const searchChat = (search, selectedCluster) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes?.email;
  const userId = user.username;

  const postData = {
    userId,
    email,
    search,
    selectedCluster,
  };

  dispatch({
    type: SEARCH_LOADING,
    payload: true,
  });
  dispatch({
    type: SEARCH_OUTPUT,
    payload: '',
  });
  dispatch({
    type: SEARCH_OUTPUT_RESOURCES,
    payload: [],
  });

  try {
    const res = await axios.post(INTERNAL_SEARCH, postData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
    });

    if (res && res.data && res.data.success === true) {
      const resources = await getResources(res.data.context);
      dispatch({
        type: SEARCH_OUTPUT,
        payload: res.data,
      });
      dispatch({
        type: SEARCH_OUTPUT_RESOURCES,
        payload: resources,
      });
      dispatch({
        type: SEARCH_LOADING,
        payload: false,
      });
    } else {
      toast.error(res?.data?.message);
      dispatch({
        type: SEARCH_LOADING,
        payload: false,
      });
      dispatch({
        type: SEARCH_OUTPUT,
        payload: '',
      });
      dispatch({
        type: SEARCH_OUTPUT_RESOURCES,
        payload: [],
      });
    }
  } catch (err) {
    toast.error('Woops, we were unable to get your answer! Please try again.');
    dispatch({
      type: SEARCH_LOADING,
      payload: false,
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log('error', err.response.data);
      console.log('error', err.response.status);
    } else if (err.request) {
      console.log('error', err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

export const removeConversation = () => async (dispatch) => {
  dispatch({
    type: REMOVE_SEARCH_OUTPUTS,
    payload: '',
  });
};

export const removeResources = () => (dispatch) => {
  dispatch({
    type: REMOVE_RESOURCES,
    payload: [],
  });
};
