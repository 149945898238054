import { Auth } from 'aws-amplify';
import axios from 'axios';
import toast from 'react-hot-toast';
import { v4 } from 'uuid';
import getFileSize from '../../../helpers/getFileSize';
import { TRAIN_V2 } from '../../API';
import { TRAINING_COMPLETED, TRAINING_IN_PROGRESS } from '../../types';
import { checkDocumentBeforeUpload } from '../uploadDocument/checkDocumentEntry';
import { removeFailedChunksFromPinecone } from './removeFailedChunksFromPinecone';
import { uploadDocumentsToExternalDb } from './uploadDocumenetsToExternalDb';
import { uploadDocumentsToS3 } from './uploadDocumentToS3';

const sendChunk = async (fileObj, token) => {
  try {
    const response = await axios.post(TRAIN_V2, fileObj, {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    // console.log(err);
    return null;
  }
};

export const trainDocuments = (chunkedOutputs) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user?.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userId = user?.username;
  const files = chunkedOutputs.map((item) => item.file);
  const fileOutputs = files.map((item) => ({ name: item.name, type: item.type }));

  const documentResponse = await checkDocumentBeforeUpload(fileOutputs);

  dispatch({
    type: TRAINING_IN_PROGRESS,
    payload: { training: true, files },
  });

  if (!documentResponse || documentResponse.success === false) {
    toast.error(`${documentResponse.message || 'Error training document please try again'}`);
    dispatch({
      type: TRAINING_IN_PROGRESS,
      payload: { training: false, files: [] },
    });
  } else {
    console.log('---- ready to train', chunkedOutputs);

    // Arrays to store initial file training info and output results
    const fileTrainingInitial = [];
    const fileTrainingOutput = [];

    // Loop through chunkedOutputs using Promise.all
    await Promise.all(
      chunkedOutputs.map(async (element) => {
        // Generate a unique file ID
        const fileId = v4();
        const chunkSubsetSize = 50;
        const totalChunks = element.chunks.length;
        const totalIterations = Math.ceil(totalChunks / chunkSubsetSize);

        // Store initial file training info
        fileTrainingInitial.push({ id: fileId, chunkIteration: totalIterations });

        const promises = [];

        // Split chunks and send each chunk subset
        let counter = 0;
        for (let i = 0; i < element.chunks.length; i += chunkSubsetSize) {
          counter += 1;
          const chunkSubset = element.chunks.slice(i, i + chunkSubsetSize);
          promises.push(
            sendChunk(
              {
                email,
                userId,
                name: element.file.name,
                size: getFileSize(element.file.size),
                type: element.file.type,
                chunkSubset,
                fileId,
                counter,
              },
              token
            )
          );
        }

        // Wait for all promises to resolve
        const results = await Promise.all(promises);

        // Check if all chunks were successfully uploaded for this file
        const allChunksUploaded =
          results.length === totalIterations && results.every((result) => result && result.success);

        // Extract the name from the first successful result
        const successResult = results.find((result) => result);
        const fileName = successResult ? successResult.name : '';
        const message = successResult ? successResult.message : '';
        const fileType = successResult ? successResult.type : '';

        // Store the file training output
        fileTrainingOutput.push({
          id: fileId,
          success: allChunksUploaded,
          name: fileName,
          type: fileType,
          message,
        });
      })
    );

    // console.log('---- fileTrainingOutput', fileTrainingOutput);

    await removeFailedChunksFromPinecone(fileTrainingOutput);

    const externalRes = await uploadDocumentsToExternalDb(fileTrainingOutput);
    await uploadDocumentsToS3(fileTrainingOutput, files);

    dispatch({
      type: TRAINING_IN_PROGRESS,
      payload: { training: false, files: [] },
    });

    dispatch({
      type: TRAINING_COMPLETED,
      payload: externalRes,
    });
  }
};
