import { Box, Breadcrumbs, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useResponsive from '../../../../hooks/useResponsive';

export default function NoCluster() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <>
      <Container sx={{ padding: '0px' }} className="p-10 lg:py-14">
        <Box sx={{ width: '100%', mt: 4, pb: 10 }}>
          <Grid spacing={3} container className="flex justify-center align-center">
            <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ pb: 3, display: isDesktop ? 'flex' : 'block' }} className=" justify-between align-center">
                <Box>
                  <Typography variant="h4">Cluster</Typography>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/clusters">
                      Clusters
                    </Link>

                    <Typography color="text.primary">Edit cluster</Typography>
                  </Breadcrumbs>
                </Box>
              </Box>
              <Box>
                <Card>
                  <CardContent>
                    <Typography variant="h4">Cluster not found</Typography>
                    <Typography>Please refresh this page or try selecting a different cluster.</Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
