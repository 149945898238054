import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import store from './store/store';
import './assets/styles/style.css';
import './output.css';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider>
            <ScrollToTop />
            <Toaster position="top-right" reverseOrder={false} />
            <Router />
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
