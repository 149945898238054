import { Grid, Container, Card, CardContent } from '@mui/material';
import HubspotForm from 'react-hubspot-form';
import { withAuth } from '../../../components/HOC/withAuth';

function ContactUs() {
  return (
    <>
      <div className="relative" style={{ paddingTop: '35px' }}>
        <div className="max-w-4xl mx-auto text-center" style={{ paddingTop: '35px' }}>
          <h1 style={{ marginTop: '10px' }} className="text-3xl font-bold text-gray-800 sm:text-4xl">
            Contact Us
          </h1>
          <p className="mt-1 text-gray-600">Contact us below and one of our team members will be with you shortly</p>
        </div>

        <Container>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <HubspotForm
                    portalId="22711473"
                    formId="ca14132c-0097-475b-9219-72a856b45537"
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default withAuth(ContactUs);
