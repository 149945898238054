import { useState } from 'react';
import { Button, Container, Box, Grid, Typography, Card, CardContent, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { toast } from 'react-hot-toast';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useResponsive from '../../../../hooks/useResponsive';
import { withAuth } from '../../../../components/HOC/withAuth';
import { useGetAllClusters } from '../../../../hooks/useGetAllClusters';
import { deleteCluster } from '../../../../store/actions/clusters/removeCluster';

function Cluster() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [removeCluster, setRemoveCluster] = useState({});
  const isDesktop = useResponsive('up', 'md');
  const { allClusters, allClustersLoading } = useGetAllClusters();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRemoveCluster({});
  };

  const handleRemove = (cluster, event) => {
    event.stopPropagation();
    setRemoveCluster(cluster);
    handleClickOpen();
  };

  const handleRowClick = (params) => {
    // console.log('Clicked row ID:', params.row.cluster_id);
    navigate(`/cluster/${params.row.cluster_id}`);
  };

  const handleDelete = () => {
    if (!removeCluster || removeCluster.cluster_name.length === 0) {
      toast.error('please select a cluster');
    } else {
      dispatch(deleteCluster(removeCluster));
      setOpen(false);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'cluster_name',
      headerName: 'Cluster Name',
      width: 450,
      headerAlign: 'left',
      align: 'left',
      editable: true,
    },
    {
      field: 'date_created',
      headerName: 'Cluster Date Created',
      width: 250,
      headerAlign: 'right',
      align: 'right',
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={(e) => handleRemove(params.row, e)}>
            <DeleteIcon style={{ color: '#ff5630' }} />
          </IconButton>
        </>
      ),
    },
  ];

  if (allClustersLoading) {
    return (
      <Container sx={{ padding: '0px' }} className="p-10 lg:py-14">
        <Box sx={{ width: '100%', mt: 4, pb: 10 }}>
          <Grid spacing={3} container className="flex justify-center align-center">
            <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{ pt: 4, pb: 3, display: isDesktop ? 'flex' : 'block' }}
                className=" justify-between align-center"
              >
                <Box>
                  <Typography variant="h4">Clusters</Typography>
                  <Typography sx={{ color: 'gray' }}>Your documents grouped together</Typography>
                </Box>
                <Box sx={{ mt: isDesktop ? 0 : 2 }}>
                  <Button variant="outlined" onClick={(e) => navigate('/create-cluster')}>
                    <AddIcon style={{ marginRight: '8px' }} />
                    Create New Cluster
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box sx={{ height: 400, width: '100%' }}>
                <Card>
                  <CardContent>
                    <CircularProgress />
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Container sx={{ padding: '0px' }} className="p-5 lg:py-14">
        <Box sx={{ width: '100%', mt: 4, pb: 10 }}>
          <Grid spacing={3} container className="flex justify-center align-center">
            <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ pb: 3, display: isDesktop ? 'flex' : 'block' }} className=" justify-between align-center">
                <Box>
                  <Typography variant="h4">Clusters</Typography>
                  <Typography sx={{ color: 'gray' }}>Your documents grouped together</Typography>
                </Box>
                <Box sx={{ mt: isDesktop ? 0 : 2 }}>
                  <Button variant="outlined" onClick={(e) => navigate('/create-cluster')}>
                    <AddIcon style={{ marginRight: '8px' }} />
                    Create New Cluster
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ overflowX: 'auto', width: '100%' }}>
                <Card>
                  <CardContent>
                    {allClustersLoading ? (
                      <CircularProgress />
                    ) : (
                      <DataGrid
                        sx={{
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none',
                          },
                          '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        rows={[...allClusters].reverse()}
                        columns={columns}
                        onRowClick={handleRowClick}
                        pageSize={10}
                        disableSelectionOnClick
                      />
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure you want to delete?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are deleting cluster -{' '}
              <span className="font-bold">{removeCluster && removeCluster.cluster_name}.</span> Are you sure you wish to
              proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={handleDelete}
              autoFocus
              variant="outlined"
              startIcon={<DeleteIcon />}
              style={{ backgroundColor: '#ff5630', color: 'white', '&:hover': { backgroundColor: '#ff4419' } }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

export default withAuth(Cluster);
