import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Box, Typography, CardContent, IconButton, Breadcrumbs } from '@mui/material';
import Grid from '@mui/material/Grid';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Card from '@mui/material/Card';
import { toast } from 'react-hot-toast';
import TextField from '@mui/material/TextField';
import useResponsive from '../../../../hooks/useResponsive';
import { useGetAllDocuments } from '../../../../hooks/useGetAllDocuments';
import DocumentSelectDialog from '../../../../components/dialogs/DocumentSelectDialog';
import FileTypeImage3D from '../../../../helpers/FileTypeImage3D';
import { createCluster } from '../../../../store/actions/clusters/createClusters';
import { CLUSTER_CREATED } from '../../../../store/types';
import { withAuth } from '../../../../components/HOC/withAuth';
import { checkUserAccess } from '../../../../store/actions/user/checkAccess';

function CreateCluster() {
  const isDesktop = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allDocuments, allDocumentsLoading } = useGetAllDocuments();
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const clusterCreatedStatus = useSelector((state) => state.clusters.clusterCreatedStatus);

  useEffect(() => {
    if (clusterCreatedStatus && clusterCreatedStatus === true) {
      dispatch({
        type: CLUSTER_CREATED,
        payload: false,
      });
      navigate('/clusters');
    }
  }, [clusterCreatedStatus]);

  const handleRemoveItem = (item) => {
    setSelectedDocuments(selectedDocuments.filter((doc) => doc !== item));
  };

  const handleCreateNewCluster = async () => {
    dispatch({
      type: CLUSTER_CREATED,
      payload: false,
    });
    if (!clusterName || clusterName.length === 0) {
      toast.error('Cluster name is missing');
    } else if (!selectedDocuments || selectedDocuments.length === 0) {
      toast.error('Please select documents to create cluster');
    } else {
      const access = await checkUserAccess();
      if (access && access.success === false) {
        toast.error(access.message);
      } else {
        dispatch(createCluster(clusterName, selectedDocuments));
      }
    }
  };

  if (allDocumentsLoading) {
    return (
      <Container sx={{ padding: '0px' }} className="p-10 lg:py-14">
        <Box sx={{ width: '100%', mt: 4, pb: 3 }}>
          <Grid spacing={3} container className="flex justify-center align-center">
            <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ pb: 2, display: isDesktop ? 'flex' : 'block' }} className=" justify-between align-center">
                <Box>
                  <Typography style={{ paddingTop: '0px' }} variant="h4">
                    Cluster
                  </Typography>
                  <Typography sx={{ color: 'gray' }}>Create a new cluster</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Card>
          <CardContent sx={{ p: isDesktop ? 5 : 0 }}>
            <Typography>Loading Documents ...</Typography>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container sx={{ padding: '0px' }} className="p-5 lg:py-14">
      <Box sx={{ width: '100%', mt: 4, pb: 3 }}>
        <Grid spacing={3} container className="flex justify-center align-center">
          <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ pt: 2, pb: 2, display: isDesktop ? 'flex' : 'block' }} className=" justify-between align-center">
              <Box>
                <Typography variant="h4">Cluster</Typography>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" to="/clusters">
                    Clusters
                  </Link>

                  <Typography color="text.primary">Create new cluster</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Card>
        <CardContent sx={{ p: isDesktop ? 5 : 2.5 }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item md={12} sx={{ width: '100%' }}>
              <TextField
                style={{ width: '100%' }}
                id="outlined-basic"
                label="Cluster Name"
                variant="outlined"
                onChange={(e) => setClusterName(e.target.value)}
              />
            </Grid>
            <Grid item md={12}>
              <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                {selectedDocuments.map((item) => (
                  <Card
                    key={item.id}
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <CardContent
                      style={{
                        paddingTop: '14px',
                        paddingBottom: '14px',
                        borderRadius: '12px',
                        border: '1px dashed rgb(103, 114, 138)',
                        cursor: 'pointer',
                      }}
                    >
                      <Box>
                        <Typography>{item.document_name}</Typography>
                        <Box className="flex justify-between items-center">
                          <div className="flex items-center">
                            <div style={{ width: isDesktop ? '20%' : '20%' }}>
                              <FileTypeImage3D name={item.document_name || ''} />
                            </div>
                            <Typography sx={{ fontSize: '14px', color: 'gray', marginLeft: '6px' }}>
                              {item.document_type}
                            </Typography>
                          </div>
                          <div>
                            <IconButton aria-label="delete" onClick={(e) => handleRemoveItem(item)}>
                              <CancelTwoToneIcon />
                            </IconButton>
                          </div>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </div>

              <DocumentSelectDialog
                allDocuments={allDocuments}
                setSelectedDocuments={setSelectedDocuments}
                selectedDocuments={selectedDocuments}
              />
            </Grid>
            <Grid item md={12} className="flex justify-end">
              {selectedDocuments && selectedDocuments.length >= 1 ? (
                <>
                  <button type="submit" className="custom-button-dark" onClick={handleCreateNewCluster}>
                    Create New Cluster
                  </button>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default withAuth(CreateCluster);
