import { Container } from '@mui/material';
import ViewTrainedDocuments from './ViewTrainedDocuments';

export default function UploadedDocuments() {
  return (
    <>
      <Container className="p-5 lg:py-14">
        <ViewTrainedDocuments />
      </Container>
    </>
  );
}
